import i18n from '@/plugins/i18n'
import {
  COMPOSITION_CANONICAL_SECTION_PREFIX,
  COMPOSITION_CAS_NUMBER_KEY,
  COMPOSITION_CLASSIFICATION_KEY,
  COMPOSITION_COMPONENT_KEY,
  COMPOSITION_CONCENTRATION_KEY,
  COMPOSITION_EG_NUMBER_KEY,
  COMPOSITION_INDEX_NR_KEY,
  ClassificationCanonicalsSuffixesEnum,
} from './approval'

export interface SDSSection {
  id: string
  index?: number
  name: string
  items?: SDSSection[]
}

export interface SDSFullSection extends SDSSection {
  subsections?: SDSFullSection[]
  formCanonicalOptions: Array<{ name: string; value: string; helpText?: string }>
}

export interface SDSFullSectionWithControls extends SDSFullSection {
  subsections?: SDSFullSectionWithControls[]
  show: boolean
  isChecked: boolean
  totalOfItems?: number
}

export interface SDSSectionWithControls extends SDSSection {
  show: boolean
}

export interface SDSKey {
  system: string
  valArea: string
  rating: string
  lang: string
  materialNumber: string
}

export const DEFAULT_SDS_KEY: SDSKey = {
  system: 'sys',
  valArea: 'reg_world',
  rating: 'public',
  lang: 'de',
  materialNumber: 'NONE',
}

export const SAP_RELATED_DATA_ASSETS: string =
  (window.ENV.SAP_RELATED_DATA_ASSETS && window.ENV.SAP_RELATED_DATA_ASSETS !== '**SAP_RELATED_DATA_ASSETS**'
    ? window.ENV.SAP_RELATED_DATA_ASSETS
    : import.meta.env.VITE_SAP_RELATED_DATA_ASSETS) || ''

export const SPECIAL_CANONICAL_TENSOR_ITEM = 'model-tensor_SEC_9'

export const CLASSIFICATION_SECTION_PREFIX = 'SEC_2_1'

export const CLASSIFICATION_CANONICAL_NAME = 'SEC_2_1.CLASSIFICATION'

export const CLASSIFICATION_PREFIX_KEY = 'CLASSIFICATION_'

export const COMPOSITION_SECTION_PREFIX = 'SEC_3_2'

export const COMPOSITION_PREFIX_KEY = 'COMPOSITION_'

export const ITEMS_PREFIXES_TO_GROUP = [COMPOSITION_PREFIX_KEY, CLASSIFICATION_PREFIX_KEY]

export const SOURCE_SYSTEM_OPTIONS = [{ name: 'sys', value: 'sys' }]

export const RATING_OPTIONS = [{ name: 'public', value: 'public' }]

export const VALIDITY_AREA_OPTIONS = [{ name: 'reg world', value: 'reg_world' }]

export const LANGUAGE_OPTIONS = [
  { name: 'EN', value: 'en' },
  { name: 'DE', value: 'de' },
]

export const SPECIFICATION_NUMBER_CANONICAL_SUFFIX = 'NUMBER'

export const SIMULATION_TYPE_IDENTIFIER = 'SEC_0.TYPE_OF_CHANGE'
export const SDS_CANONICAL_DECIMAL_NOTATION = 'SEC_0_1.NOTATION'
export const SDS_CANONICAL_NAME = 'SEC_1_1.NAME'
export const SDS_CANONICAL_CAS = 'SEC_1_1.CAS'
export const SDS_CANONICAL_INDEX_NR = 'SEC_1_1.INDEX_NR'
export const SDS_CANONICAL_EG_NO = 'SEC_1_1.EG_NR'
export const SDS_CANONICAL_PRODUCT_NUMBER = 'SEC_1_1.NUMBER'
export const SDS_CANONICAL_MANUFACTURER = 'SEC_1_3.MANUFACTURER'
export const SDS_CANONICAL_DATA_OWNER = 'SEC_1_3.DATA_OWNER'
export const SDS_CANONICAL_PICTOGRAM = 'SEC_2_2_1.SYMBOL'
export const SDS_CANONICAL_PICTOGRAM_LABELLING = 'SEC_2_2_2.SIGNAL'
export const SDS_CANONICAL_EUH_PHRASE = 'SEC_2_2.EUH_STAT'
export const SDS_CANONICAL_EMERGENCY_TELEPHONE = 'SEC_1_4.EMERGENCY_TELEPHONE'
export const SDS_CANONICAL_SPEC_NAME = 'SEC_0.SPEC_NAME'
export const SDS_CANONICAL_SPEC_ID = 'SEC_0.SPEC_ID'
export const SDS_CANONICAL_THRESHOLD = 'SEC_0.THRESHOLD'
export const SDS_CANONICAL_TEXT = 'SEC_0.TEXT'
export const SDS_CANONICAL_RECORD_NUMBER = 'SEC_0.RECORD_NO'
export const SDS_CANONICAL_CLASSIFICATION_HAZARDCLASS = `${CLASSIFICATION_SECTION_PREFIX}.${ClassificationCanonicalsSuffixesEnum.HazardClass}`
export const SDS_CANONICAL_CLASSIFICATION_CATEGORY = `${CLASSIFICATION_SECTION_PREFIX}.${ClassificationCanonicalsSuffixesEnum.Category}`
export const SDS_CANONICAL_CLASSIFICATION_ROUTE_OF_EXPOSURE = `${CLASSIFICATION_SECTION_PREFIX}.${ClassificationCanonicalsSuffixesEnum.RouteOfExposure}`
export const SDS_CANONICAL_CLASSIFICATION_TARGET_ORGAN = `${CLASSIFICATION_SECTION_PREFIX}.${ClassificationCanonicalsSuffixesEnum.Organ}`
export const SDS_CANONICAL_CLASSIFICATION_HAZARD_STATEMENT = `${CLASSIFICATION_SECTION_PREFIX}.${ClassificationCanonicalsSuffixesEnum.HazardStatement}`
export const SDS_CANONICAL_CLASSIFICATION_ADDITIONAL_INFO = `${CLASSIFICATION_SECTION_PREFIX}.${ClassificationCanonicalsSuffixesEnum.AddInfo}`
export const SDS_CANONICAL_CLASSIFICATION_REMARKS = `${CLASSIFICATION_SECTION_PREFIX}.${ClassificationCanonicalsSuffixesEnum.Remark}`
export const SDS_CANONICAL_CLASSIFICATION_REG_BASE = `${CLASSIFICATION_SECTION_PREFIX}.${ClassificationCanonicalsSuffixesEnum.RegulatoryBase}`
export const SDS_CANONICAL_CLASSIFICATION_HCODE = `${CLASSIFICATION_SECTION_PREFIX}.${ClassificationCanonicalsSuffixesEnum.HCode}`
export const SDS_CANONICAL_COMPOSITION_CONCENTRATION = `${COMPOSITION_CANONICAL_SECTION_PREFIX}.${COMPOSITION_CONCENTRATION_KEY}`
export const SDS_CANONICAL_COMPOSITION_CAS_NUMBER = `${COMPOSITION_CANONICAL_SECTION_PREFIX}.${COMPOSITION_CAS_NUMBER_KEY}`

export const SDS_CLASSIFICATION_CANONICAL_LIST = [
  SDS_CANONICAL_CLASSIFICATION_HAZARDCLASS,
  SDS_CANONICAL_CLASSIFICATION_CATEGORY,
  SDS_CANONICAL_CLASSIFICATION_ROUTE_OF_EXPOSURE,
  SDS_CANONICAL_CLASSIFICATION_TARGET_ORGAN,
  SDS_CANONICAL_CLASSIFICATION_HAZARD_STATEMENT,
  SDS_CANONICAL_CLASSIFICATION_ADDITIONAL_INFO,
  SDS_CANONICAL_CLASSIFICATION_REMARKS,
  SDS_CANONICAL_CLASSIFICATION_REG_BASE,
  SDS_CANONICAL_CLASSIFICATION_HCODE,
]

export const HAZARD_CLASSIFICATION_FIRST_LEVEL_ID = 'SEC_2'
export const COMPOSITION_FIRST_LEVEL_ID = 'SEC_3'
export const PHYSICAL_AND_CHEMICAL_PROPERTIES_FIRST_LEVEL_ID = 'SEC_9'

export const SDS_TREE: SDSSection[] = [
  {
    id: 'SEC_1',
    index: 1,
    // eslint-disable-next-line
    // @ts-ignore
    name: i18n.global.t('Identification'),
    items: [
      { id: 'SEC_1_1', name: i18n.global.t('Product Identifier') },
      {
        id: 'SEC_1_2',
        name: i18n.global.t('Relevant identified uses of the substance or mixture and uses advised against'),
      },
      { id: 'SEC_1_3', name: i18n.global.t('Details of the supplier of the safety data sheet') },
      { id: 'SEC_1_4', name: i18n.global.t('Emergency telephone number') },
    ],
  },
  {
    id: HAZARD_CLASSIFICATION_FIRST_LEVEL_ID,
    index: 2,
    name: i18n.global.t('Hazards identification'),
    items: [
      { id: 'SEC_2_1', name: i18n.global.t('Classification of the substance or mixture') },
      { id: 'SEC_2_2', name: i18n.global.t('Label elements') },
      { id: 'SEC_2_3', name: i18n.global.t('Other hazards') },
    ],
  },
  {
    id: COMPOSITION_FIRST_LEVEL_ID,
    index: 3,
    name: i18n.global.t('Composition/information on ingredients'),
    items: [
      { id: 'SEC_3_1', name: i18n.global.t('Substances') },
      { id: 'SEC_3_2', name: i18n.global.t('Mixtures') },
    ],
  },
  {
    id: 'SEC_4',
    index: 4,
    name: i18n.global.t('First aid measures'),
    items: [
      { id: 'SEC_4_1', name: i18n.global.t('Description of first aid measures') },
      { id: 'SEC_4_2', name: i18n.global.t('Most important symptoms and effects, both acute and delayed') },
      {
        id: 'SEC_4_3',
        name: i18n.global.t('Indication of any immediate medical attention and special treatment needed'),
      },
    ],
  },
  {
    id: 'SEC_5',
    index: 5,
    name: i18n.global.t('Fire-fighting measures'),
    items: [
      { id: 'SEC_5_1', name: i18n.global.t('Extinguishing media') },
      { id: 'SEC_5_2', name: i18n.global.t('Special hazards arising from the substance or mixture') },
      { id: 'SEC_5_3', name: i18n.global.t('Advice for firefighters') },
    ],
  },
  {
    id: 'SEC_6',
    index: 6,
    name: i18n.global.t('Accidental release measures'),
    items: [
      { id: 'SEC_6_1', name: i18n.global.t('Personal precautions, protective equipment and emergency procedures') },
      { id: 'SEC_6_2', name: i18n.global.t('Environmental precautions') },
      { id: 'SEC_6_3', name: i18n.global.t('Methods and material for containment and cleaning up') },
      { id: 'SEC_6_4', name: i18n.global.t('Reference to other sections') },
    ],
  },
  {
    id: 'SEC_7',
    index: 7,
    name: i18n.global.t('Handling and storage'),
    items: [
      { id: 'SEC_7_1', name: i18n.global.t('Precautions for safe handling') },
      { id: 'SEC_7_2', name: i18n.global.t('Conditions for safe storage, including any incompatibilities') },
      { id: 'SEC_7_3', name: i18n.global.t('Specific end use(s)') },
    ],
  },
  {
    id: 'SEC_8',
    index: 8,
    name: i18n.global.t('Exposure controls/personal protection'),
    items: [
      { id: 'SEC_8_1', name: i18n.global.t('Control parameters') },
      { id: 'SEC_8_2', name: i18n.global.t('Exposure controls') },
    ],
  },
  {
    id: PHYSICAL_AND_CHEMICAL_PROPERTIES_FIRST_LEVEL_ID,
    index: 9,
    name: i18n.global.t('Physical and chemical properties'),
    items: [
      { id: 'SEC_9_1', name: i18n.global.t('Information on basic physical and chemical properties') },
      { id: 'SEC_9_2', name: i18n.global.t('Other information') },
    ],
  },
  {
    id: 'SEC_10',
    index: 10,
    name: i18n.global.t('Stability and reactivity'),
    items: [
      { id: 'SEC_10_1', name: i18n.global.t('Reactivity') },
      { id: 'SEC_10_2', name: i18n.global.t('Chemical stability') },
      { id: 'SEC_10_3', name: i18n.global.t('Possibility of hazardous reactions') },
      { id: 'SEC_10_4', name: i18n.global.t('Conditions to avoid') },
      { id: 'SEC_10_5', name: i18n.global.t('Incompatible materials') },
      { id: 'SEC_10_6', name: i18n.global.t('Hazardous decomposition products') },
    ],
  },
  {
    id: 'SEC_11',
    index: 11,
    name: i18n.global.t('Toxicological information'),
    items: [
      { id: 'SEC_11_1', name: i18n.global.t('Information on toxicological effects') },
      { id: 'SEC_11_2', name: i18n.global.t('Information on other hazards') },
    ],
  },
  {
    id: 'SEC_12',
    index: 12,
    name: i18n.global.t('Ecological information'),
    items: [
      { id: 'SEC_12_1', name: i18n.global.t('Toxicity') },
      { id: 'SEC_12_2', name: i18n.global.t('Persistence and degradability') },
      { id: 'SEC_12_3', name: i18n.global.t('Bioaccumulative potential') },
      { id: 'SEC_12_4', name: i18n.global.t('Mobility in soil') },
      { id: 'SEC_12_5', name: i18n.global.t('Results of PBT and vPvB assessment') },
      { id: 'SEC_12_6', name: i18n.global.t('Other adverse effects') },
    ],
  },
  {
    id: 'SEC_13',
    index: 13,
    name: i18n.global.t('Disposal considerations'),
    items: [{ id: 'SEC_13_1', name: i18n.global.t('Waste treatment methods') }],
  },
  {
    id: 'SEC_14',
    index: 14,
    name: i18n.global.t('Transport information'),
    items: [
      { id: 'SEC_14_1', name: i18n.global.t('UN number') },
      { id: 'SEC_14_2', name: i18n.global.t('UN proper shipping name') },
      { id: 'SEC_14_3', name: i18n.global.t('Transport hazard class(es)') },
      { id: 'SEC_14_4', name: i18n.global.t('Packing group') },
      { id: 'SEC_14_5', name: i18n.global.t('Environmental hazards') },
      { id: 'SEC_14_6', name: i18n.global.t('Special precautions for user') },
      {
        id: 'SEC_14_7',
        name: i18n.global.t('Transport in bulk according to Annex II of MARPOL73/78 and the IBC Code'),
      },
    ],
  },
  {
    id: 'SEC_15',
    index: 15,
    name: i18n.global.t('Regulatory information'),
    items: [
      {
        id: 'SEC_15_1',
        name: i18n.global.t(
          'Safety, health and environmental regulations/legislation specific for the substance or mixture'
        ),
      },
      { id: 'SEC_15_2', name: i18n.global.t('Chemical safety assessment') },
    ],
  },
]

export const TDS_CANONICAL_NAME = 'PRODUCT_INFORMATION.NAME'

export const TDS_TREE: SDSSection[] = [
  {
    id: 'PRODUCT_INFORMATION',
    index: 1,
    name: i18n.global.t('Product Information'),
  },
  {
    id: 'PRODUCT_PROPERTIES',
    index: 2,
    name: i18n.global.t('Product properties'),
    items: [
      {
        id: 'PRODUCT_PROPERTIES_GENERAL',
        name: i18n.global.t('General'),
      },
      { id: 'PRODUCT_PROPERTIES_SINGLE_COMPONENT_A', name: i18n.global.t('Component A') },
      { id: 'PRODUCT_PROPERTIES_SINGLE_COMPONENT_B', name: i18n.global.t('Component B') },
      { id: 'PRODUCT_PROPERTIES_MIXED_PRODUCT', name: i18n.global.t('Mixed product') },
      {
        id: 'PRODUCT_PROPERTIES_MIXED_CURED_PRODUCT_THERMAL',
        name: i18n.global.t('Thermal properties'),
      },
      {
        id: 'PRODUCT_PROPERTIES_MIXED_CURED_PRODUCT_MECHANICAL',
        name: i18n.global.t('Mechanical properties'),
      },
      {
        id: 'PRODUCT_PROPERTIES_MIXED_CURED_PRODUCT_ELECTRICAL',
        name: i18n.global.t('Electrical properties'),
      },
    ],
  },
  {
    id: 'PRODUCT_PROCESSING_INSTRUCTIONS',
    index: 3,
    name: i18n.global.t('Processing instructions'),
  },
]

export const CUSTOM_FALLBACK_TREE: SDSSection[] = [
  {
    id: 'CUSTOM_FALLBACK_SECTION',
    index: 1,
    name: i18n.global.t('Items'),
  },
]

export const TDS_STRUCTURE = {
  name: TDS_CANONICAL_NAME,
  searchResultsItems: [],
  tree: TDS_TREE,
}

export const SDS_STRUCTURE = {
  name: SDS_CANONICAL_NAME,
  searchResultsItems: [
    SDS_CANONICAL_CAS,
    SDS_CANONICAL_PRODUCT_NUMBER,
    'SEC_1_1.REG_OK',
    'SEC_1_1.REG_NR',
    SDS_CANONICAL_MANUFACTURER,
    'SEC_1_3.EMAIL',
  ],
  tree: SDS_TREE,
}

export const MATERIAL_CLASSIFICATION_COLUMNS = [
  {
    name: i18n.global.t('HCODE'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.HCode,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Hazard statements'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.HazardStatement,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Hazard class'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.HazardClass,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Category'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.Category,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Additional information'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.AddInfo,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Organ'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.Organ,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Route of exposure'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.RouteOfExposure,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Remark'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.Remark,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Regulatory Base'),
    canonicalPreffix: ClassificationCanonicalsSuffixesEnum.RegulatoryBase,
    colClasses: 'col-1',
  },
]

export const COMPOSITION_REFERENCE_COLUMN_KEY = 'COMPOSITION_REFERENCE'
export const COMPOSITION_CREATE_MIXTURE_COLUMN_KEY = 'COMPOSITION_CREATE_MIXTURE'

export const MATERIAL_COMPOSITION_COLUMNS = [
  {
    name: i18n.global.t('Reference'),
    canonicalPreffix: COMPOSITION_REFERENCE_COLUMN_KEY,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('CAS-No'),
    canonicalPreffix: COMPOSITION_CAS_NUMBER_KEY,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Name'),
    canonicalPreffix: COMPOSITION_COMPONENT_KEY,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('Concentration'),
    canonicalPreffix: COMPOSITION_CONCENTRATION_KEY,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Classification'),
    canonicalPreffix: COMPOSITION_CLASSIFICATION_KEY,
    colClasses: 'col-2',
  },
  {
    name: i18n.global.t('EG No.'),
    canonicalPreffix: COMPOSITION_EG_NUMBER_KEY,
    colClasses: 'col-1',
  },
  {
    name: i18n.global.t('Index Nr.'),
    canonicalPreffix: COMPOSITION_INDEX_NR_KEY,
    colClasses: 'col-1',
  },
  {
    name: '',
    canonicalPreffix: COMPOSITION_CREATE_MIXTURE_COLUMN_KEY,
    colClasses: 'col-2',
  },
]
