import { reactive, ref } from 'vue'
import { dataAssetsApi, dataFlowApi } from '@/api'
import { DataAssetInfo } from '@coac-gmbh/saifty-main-apis'
import {
  DEFAULT_FLOW_LANGUAGE,
  DEFAULT_FLOW_TYPE,
  FlowWithData,
  SDS_TREE,
  SDS_PASSPORT_ID,
  DEFAULT_FLOW_CONFIDENCE,
} from '@/config'

export default function useWizard() {
  const DEFAULT_DATA_ASSET_NAME = 'Default'
  const defaultExtractionID = ref<string>('')
  const defaultExtraction = reactive<FlowWithData>({
    name: 'SDS Extraction',
    flowType: DEFAULT_FLOW_TYPE,
    data: {
      extract: {
        allEchaIngredients: false,
        allEPAIngredients: false,
        quickExtraction: true,
        passportId: SDS_PASSPORT_ID,
        displayAllPassportItems: false,
        sections: SDS_TREE.map((section) => section.id),
        languages: [DEFAULT_FLOW_LANGUAGE],
        ocr: false,
      },
      validate: {
        confidence: DEFAULT_FLOW_CONFIDENCE,
        lookup_phrases: false,
        echa_reach_call: false,
        epa_call: false,
        region: '',
        businessUnit: '',
        translate_from: [],
        translate_to: '',
      },
      store: {
        dataassetId: '',
        dataassetName: '',
      },
    },
  }) as FlowWithData

  async function createDefaultExtractionAndDataAsset() {
    await loadDataAsset()
    await createFlow()
  }

  async function loadDataAsset() {
    let dataAsset: DataAssetInfo | undefined
    const { data: dataAssetsResponse } = await dataAssetsApi.getAllDataAssets()

    if (dataAssetsResponse.dataAssets && dataAssetsResponse.dataAssets.length > 0) {
      // If not found then undefined is kept
      dataAsset = dataAssetsResponse.dataAssets.find((dA) => {
        return dA.name === DEFAULT_DATA_ASSET_NAME
      })
    }
    // Default Data asset not found, then let's create it
    if (!dataAsset) {
      const { data } = await dataAssetsApi.createDataAsset({ dataAssetRequest: { name: DEFAULT_DATA_ASSET_NAME } })
      dataAsset = data
    }
    // The ID and name are mandatory but in the spec is not specified
    defaultExtraction.data.store.dataassetId = dataAsset.id!
    defaultExtraction.data.store.dataassetName = dataAsset.name!
  }

  async function createFlow() {
    const { data } = await dataFlowApi.createDataFlow({ dataFlowRequest: defaultExtraction })
    // The ID is mandatory but in the spec it's not specified
    defaultExtractionID.value = data.id!
  }

  return {
    DEFAULT_DATA_ASSET_NAME,
    defaultExtractionID,
    defaultExtraction,
    createDefaultExtractionAndDataAsset,
  }
}
