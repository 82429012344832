import { DataFlowConfigurationParsed, FLOW_FALLBACK_DATA, FlowData } from '@/config'
import { DataFlow } from '@coac-gmbh/saifty-main-apis'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'
import { version } from '../../package.json'

export enum PromiseStatuses {
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

export function randomBetween(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

// Get the latest configuration of a flow and parse it to FlowData because the backend returns it as a object that can contain anything
export function getLatestConfiguration(flow: DataFlow): DataFlowConfigurationParsed {
  let data: FlowData

  if (flow.configuration && flow.configuration.length > 0) {
    const latestConfiguration =
      flow.configuration?.reduce((currentLatest, current) => {
        return Number(currentLatest.version ?? 0) > Number(current.version ?? 0) ? currentLatest : current
      }) || {}

    if (latestConfiguration.data) {
      // The saved configuration object can have any key, so we need to cast it to FlowData
      const latestConfigurationData = latestConfiguration.data as { [key: string]: any }
      data = {
        extract: {
          quickExtraction:
            latestConfigurationData.extract?.quickExtraction ?? FLOW_FALLBACK_DATA.extract.quickExtraction,
          allEchaIngredients:
            latestConfigurationData.extract?.allEchaIngredients ?? FLOW_FALLBACK_DATA.extract.allEchaIngredients,
          allEPAIngredients:
            latestConfigurationData.extract?.allEPAIngredients ?? FLOW_FALLBACK_DATA.extract.allEPAIngredients,
          passportId: latestConfigurationData.extract?.passportId ?? FLOW_FALLBACK_DATA.extract.passportId,
          displayAllPassportItems:
            latestConfigurationData.extract?.displayAllPassportItems ??
            FLOW_FALLBACK_DATA.extract.displayAllPassportItems,
          languages: latestConfigurationData.extract?.languages ?? FLOW_FALLBACK_DATA.extract.languages,
          ocr: latestConfigurationData.extract?.ocr ?? FLOW_FALLBACK_DATA.extract.ocr,
        },
        validate: {
          confidence: latestConfigurationData.validate?.confidence ?? FLOW_FALLBACK_DATA.validate.confidence,
          lookup_phrases:
            latestConfigurationData.validate?.lookup_phrases ?? FLOW_FALLBACK_DATA.validate.lookup_phrases,
          echa_reach_call:
            latestConfigurationData.validate?.echa_reach_call ?? FLOW_FALLBACK_DATA.validate.echa_reach_call,
          epa_call: latestConfigurationData.validate?.epa_call ?? FLOW_FALLBACK_DATA.validate.epa_call,
          region: latestConfigurationData.validate?.region ?? FLOW_FALLBACK_DATA.validate.region,
          businessUnit: latestConfigurationData.validate?.businessUnit ?? FLOW_FALLBACK_DATA.validate.businessUnit,
          translate_from:
            latestConfigurationData.validate?.translate_from ?? FLOW_FALLBACK_DATA.validate.translate_from,
          translate_to: latestConfigurationData.validate?.translate_to ?? FLOW_FALLBACK_DATA.validate.translate_to,
        },
        store: {
          dataassetId: latestConfigurationData.store?.dataassetId ?? FLOW_FALLBACK_DATA.store.dataassetId,
          dataassetName: latestConfigurationData.store?.dataassetName ?? FLOW_FALLBACK_DATA.store.dataassetName,
        },
      }
    } else {
      data = FLOW_FALLBACK_DATA
    }

    return {
      version: latestConfiguration.version,
      data,
    }
  } else {
    return {
      version: '1',
      data: FLOW_FALLBACK_DATA,
    }
  }
}

export function sortByDate(a: Date, b: Date, ascSorting: boolean) {
  return ascSorting ? a.getTime() - b.getTime() : b.getTime() - a.getTime()
}

export function sortAlphabetically(a: string, b: string, ascSorting: boolean) {
  if (ascSorting) {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1
    }
    return 0
  } else {
    if (a.toLowerCase() > b.toLowerCase()) {
      return -1
    }
    if (a.toLowerCase() < b.toLowerCase()) {
      return 1
    }
    return 0
  }
}

export function getFileExtension(file: string) {
  const fileParts = file.split('.')
  return fileParts[fileParts.length - 1]
}

export function arrayRange(start: number, stop: number, step: number) {
  return Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step)
}

export function areEqualObjects(first: object, second: object) {
  return Object.entries(first).sort().toString() === Object.entries(second).sort().toString() // We use 'sort' because the position is important in the comparison and finally, we cast it to string to compare
}

// Formats date to relative time strings (e.g. 3 hours ago).
// Date is a timestamp or iso date without timezone, therefore we assume it's UTC
export function dateToRelativeTimeHumanized(date: string | number, isTimestamp = false) {
  dayjs.extend(relativeTime)
  dayjs.extend(utc)
  const nowInUTC = dayjs.utc()
  if (isTimestamp) date = typeof date === 'number' ? date : Number(date)
  return dayjs.utc(date).from(nowInUTC)
}

export function formatDate(date: string, format?: string) {
  return dayjs(date).format(format ? format : 'DD-MM-YYYY')
}

export function getPromiseStatus(promise: Promise<any>) {
  const t = {}

  return Promise.race([promise, t]).then(
    (v) => (v === t ? PromiseStatuses.Pending : PromiseStatuses.Fulfilled),
    () => PromiseStatuses.Rejected
  )
}

export function isLetterOrNumber(e: KeyboardEvent) {
  if (/^[a-zA-Z0-9 ]*$/.test(e.key)) return true
  else e.preventDefault() // If not match, don't add to input text
}

export function dontAllowWhiteSpaces(e: KeyboardEvent) {
  if (/^\S*$/.test(e.key)) return true
  else e.preventDefault() // If is white space, don't add to input text
}

export function dataAccessor(object: any, path: string) {
  /* 
This function is used to pass and object and a path to search deeply in an object a return the value
- Object Example:
{
  data: {
    name: 'Example'
    value: ' Success'
  },
  status: 'Testing'
}
- Path example: 'data.name'
- Value returned: 'Example'

*/
  const deepAccesors = path.split('.')
  return deepAccesors.reduce(
    (currentValue: any, currentAccesor: any) => (currentValue[currentAccesor] ? currentValue[currentAccesor] : ''),
    object
  )
}

export function getAppVersion() {
  return version
}

export function generateUUID(): string {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const IS_PRODUCTION =
  import.meta.env.PROD ||
  import.meta.env.NODE_ENV === 'production' ||
  ('ENVIRONMENT' in window.ENV && (window.ENV.ENVIRONMENT === 'production' || window.ENV.ENVIRONMENT === 'PROD'))
