import { fileStorageApi, dataAssetsApi, exportApi, downloadPublicDocumentsApi } from '@/api'
import { DocumentMetaData } from '@coac-gmbh/library-api/models'
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import useToasts from './useToasts'
import { Document as BODocument } from '@coac-gmbh/approvals-api'
import { extension } from 'mime-types'

type LibraryExportType = 'xlsx' | 'idoc' | 'xml'

export declare enum LibraryExportTypes {
  XLSX = 'xlsx',
  IDOC = 'idoc',
  XML = 'xml',
}

/* REUSABLE STUFF RELATED TO BACK OFFICE AND DATA ASSETS/OBJECTS DOCUMENTS/FILES */

// Extend documents to allow them to be checked
export interface ValidationDocument extends BODocument {
  selected: boolean
}

export default function useDownloadDocuments() {
  const downloadingSource = ref(false)
  const { addToast } = useToasts()
  const { t } = useI18n()

  async function downloadSourcePDF(documentId: string) {
    downloadingSource.value = true
    try {
      const { data: blob } = await fileStorageApi.getFile({ fileId: documentId }, { responseType: 'blob' })

      downloadBlob(`${documentId}.pdf`, blob)
    } catch {
      addToast({ text: t('Error downloading the file'), variant: 'danger' })
    }
    downloadingSource.value = false
  }

  async function downloadFileFromFilestorage(fileId: string) {
    downloadingSource.value = true
    try {
      const { data: blob } = await fileStorageApi.getFile({ fileId: fileId }, { responseType: 'blob' })

      downloadBlob(`${fileId}`, blob)
    } catch {
      addToast({ text: t('Error downloading file'), variant: 'danger' })
    }
    downloadingSource.value = false
  }

  async function downloadPublicSourcePDF(publicSharedSuffix: string, documentId: string) {
    downloadingSource.value = true
    try {
      const { data: blob } = await downloadPublicDocumentsApi.downloadDocument(
        { publicSharedSuffix, documentId },
        { responseType: 'blob' }
      )

      downloadBlob(`${documentId}.pdf`, blob)
    } catch {
      addToast({ text: t('Error to download the file'), variant: 'danger' })
    }
    downloadingSource.value = false
  }

  async function downloadDataObject(fileName: string, dataAssetId: string, dataObjectId: string) {
    downloadingSource.value = true
    try {
      const { data: blob } = await dataAssetsApi.downloadDataObject(
        {
          dataAssetsId: dataAssetId,
          dataObjectId: dataObjectId,
        },
        { responseType: 'blob' }
      )

      downloadBlob(`${fileName || 'data-object'}.xlsx`, blob)
    } catch {
      addToast({ text: t('Error to download the file'), variant: 'danger' })
    }
    downloadingSource.value = false
  }

  async function downloadDataAsset(fileName: string, dataAssetId: string) {
    downloadingSource.value = true
    try {
      const { data: blob, headers } = await dataAssetsApi.downloadDataAsset(
        {
          dataAssetId,
        },
        { responseType: 'blob' }
      )

      downloadBlob(`${fileName || 'data-asset'}.${extension(headers['content-type'])}`, blob)
    } catch {
      addToast({ text: t('Error to download the file'), variant: 'danger' })
    }
    downloadingSource.value = false
  }

  async function downloadDataAssetHazardSubstance(fileName: string, dataAssetId: string) {
    downloadingSource.value = true
    try {
      const { data: blob } = await dataAssetsApi.downloadDataAssetsHazardSubstances(
        {
          dataAssetId,
        },
        { responseType: 'blob' }
      )

      downloadBlob(`${fileName || 'data-asset-hazard-substance'}.xlsx`, blob)
    } catch {
      addToast({ text: t('Error to download the file'), variant: 'danger' })
    }
    downloadingSource.value = false
  }

  async function downloadLibraryDocument(document: DocumentMetaData) {
    if (document.documentId) {
      try {
        const { data: documentData } = await fileStorageApi.getFileData({ fileId: document.documentId })
        const { data: blob } = await fileStorageApi.getFile({ fileId: document.documentId }, { responseType: 'blob' })
        if (documentData.mimeType) {
          let filename = `${document.documentId || document.materialNumber || 'document'}.${extension(
            documentData.mimeType
          )}`
          if (documentData.fileName?.includes('.')) {
            filename = documentData.fileName // if the filename already has an extension, use it
          }
          downloadBlob(filename, blob)
        }
      } catch (e) {
        addToast({ text: t('Error downloading the file'), variant: 'danger' })
      }
    } else {
      addToast({ text: 'The document does not have Id' })
    }
  }

  async function downloadObjectTemplateXLSX() {
    try {
      const blob = await fetch('/files/PassportItemsUploadTemplate.xlsx').then((response) => response.blob())
      downloadBlob('Passport Items Template.xlsx', blob)
    } catch {
      addToast({ text: t('Error downloading the file'), variant: 'danger' })
    }
  }

  async function downloadAssessmentXLSXTemplate() {
    try {
      const blob = await fetch('/files/Assessment Template.xlsx').then((response) => response.blob())
      downloadBlob('Assessment Template.xlsx', blob)
    } catch {
      addToast({ text: t('Error downloading the file'), variant: 'danger' })
    }
  }

  function downloadBlob(title: string, data: Blob) {
    const a = document.createElement('a')
    a.href = window.URL.createObjectURL(data)
    a.download = title
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    addToast({ text: t('Download Ready'), variant: 'success' })
  }

  return {
    downloadingSource,
    downloadSourcePDF,
    downloadFileFromFilestorage,
    downloadPublicSourcePDF,
    downloadDataObject,
    downloadDataAsset,
    downloadDataAssetHazardSubstance,
    downloadLibraryDocument,
    downloadObjectTemplateXLSX,
    downloadAssessmentXLSXTemplate,
  }
}
