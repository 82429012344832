import { Configuration, ApprovalApi } from '@coac-gmbh/approvals-api'
import {
  DefaultApi as RegionsConfigAPI,
  PhrasesApi as PhrasesAPI,
  LoadSpecificationDataApi as MaterialToSAPSpecificationDataApi,
} from '@coac-gmbh/saifty-phrases'
import {
  ExportAPIsApi,
  DataFlowManagementApi,
  DataAssetManagementApi,
  SearchAPIsApi,
  FavoriteAPIsApi,
  MetricsApi,
  TemplateManagementApi,
  SupplierRequestManagementApi,
  AccountManagementApi,
  RoleManagementApi,
} from '@coac-gmbh/saifty-main-apis'
import { LibraryAPIsApi } from '@coac-gmbh/library-api'
import { FileStorageApi, PublicShareApi } from '@coac-gmbh/file-storage-api'
import {
  DataobjectManagementApi as ComponentManagementApi,
  PassportManagementApi as QueryManagementApi,
  QueryGeneratorApi,
  FlowManagementApi as QueryFlowManagementApi,
} from '@coac-gmbh/saifty-query-builder'
import {
  SpecificationAPIApi as SAPSpecificationApi,
  ConfigLoadManagementAPIApi as SAPConfigLoadManagementApi,
  SpecificationFilesAPIApi as SAPSpecificationFilesApi,
  SyncSpecificationAPIApi as SAPSyncSpecificationApi,
  // SyncSpecificationFileAPIApi as SAPSyncSpecificationFileApi,
} from '@coac-gmbh/saifty-sap'
import { SyncConfigurationsAPIApi as SAPSyncSpecificationFileApi } from '@coac-gmbh/sap-sync-configuration-api'
import { DefaultApi } from '@coac-gmbh/saifty-gpt-api'
import axiosInstance from './axios'

const configCOACBO = new Configuration({
  basePath: import.meta.env.VITE_API_BO_BASE_URL || window.ENV.API_APPROVALS_BASE_URL,
})
const configPhrases = new Configuration({
  basePath: import.meta.env.VITE_API_SAIFTY_PHRASES_BASE_URL || window.ENV.API_PHRASES_BASE_URL,
})
const configSaiftyApis = new Configuration({
  basePath: import.meta.env.VITE_API_SAIFTY_APIS_BASE_URL || window.ENV.API_MONOLITH_BASE_URL,
})
const configFileSystemApis = new Configuration({
  basePath: import.meta.env.VITE_API_SAIFTY_FILE_SYSTEM_BASE_URL || window.ENV.API_FILE_SYSTEM_BASE_URL,
})
const configLibraryApis = new Configuration({
  basePath: import.meta.env.VITE_API_SAIFTY_LIBRARY_APIS_BASE_URL || window.ENV.API_LIBRARY_APIS_BASE_URL,
})
const configQueryBuilderApis = new Configuration({
  basePath: import.meta.env.VITE_API_SAIFTY_QUERY_BUILDER_BASE_URL || window.ENV.API_QUERY_BUILDER_BASE_URL,
})

const configSaiftySAPApis = new Configuration({
  basePath: import.meta.env.VITE_API_SAIFTY_SAP_BASE_URL || window.ENV.API_SAP_BASE_URL,
})

const configSaiftySyncSpecificationApis = new Configuration({
  basePath: import.meta.env.VITE_API_SAP_SYNC_CONFIG_BASE_URL || window.ENV.API_SAP_SYNC_CONFIG_BASE_URL,
})

const configSaiftyGPTApis = new Configuration({
  basePath: import.meta.env.VITE_API_SAIFTY_GPT_BASE_URL || window.ENV.API_SAIFTY_GPT_BASE_URL,
})

// COAC Back Office
export const coacBOApi = new ApprovalApi(configCOACBO, undefined, axiosInstance)

// SAIFTY Phrases
export const phrasesApi = new PhrasesAPI(configPhrases, undefined, axiosInstance)

// SAIFTY region phrases
export const regionsConfigApi = new RegionsConfigAPI(configPhrases, undefined, axiosInstance)

export const materialToSAPSpecificationDataApi = new MaterialToSAPSpecificationDataApi(
  configPhrases,
  undefined,
  axiosInstance
)

// SAIFTY Flows
export const dataFlowApi = new DataFlowManagementApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Export
export const exportApi = new ExportAPIsApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Favorites
export const favoritesApi = new FavoriteAPIsApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Search
export const searchApi = new SearchAPIsApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Data assets
export const dataAssetsApi = new DataAssetManagementApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Metrics
export const metricsApi = new MetricsApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Templates for Passports
export const templateManagementApi = new TemplateManagementApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Request management for Passports
export const supplierRequestManagementApi = new SupplierRequestManagementApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Component management for query builder
export const componentManagementApi = new ComponentManagementApi(configQueryBuilderApis, undefined, axiosInstance)

// SAIFTY Query management for query builder
export const queryManagementApi = new QueryManagementApi(configQueryBuilderApis, undefined, axiosInstance)

// SAIFTY Query builder - generator
export const queryGeneratorApi = new QueryGeneratorApi(configQueryBuilderApis, undefined, axiosInstance)

// SAIFTY Query flow management for query builder
export const queryFlowManagementApi = new QueryFlowManagementApi(configQueryBuilderApis, undefined, axiosInstance)

// SAIFTY Account Management
export const accountManagementApi = new AccountManagementApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Role Management
export const roleManagementApi = new RoleManagementApi(configSaiftyApis, undefined, axiosInstance)

// SAIFTY Library
export const libraryApi = new LibraryAPIsApi(configLibraryApis, undefined, axiosInstance)

// SAIFTY File Storage
export const fileStorageApi = new FileStorageApi(configFileSystemApis, undefined, axiosInstance)

// SAIFTY Download Documents
export const downloadPublicDocumentsApi = new PublicShareApi(configFileSystemApis, undefined, axiosInstance)

// SAIFTY SAP
export const sapSpecificationApi = new SAPSpecificationApi(configSaiftySAPApis, undefined, axiosInstance)
export const sapSpecificationFilesApi = new SAPSpecificationFilesApi(configSaiftySAPApis, undefined, axiosInstance)
export const sapSyncSpecificationFileApi = new SAPSyncSpecificationFileApi(
  configSaiftySyncSpecificationApis,
  undefined,
  axiosInstance
)

function getSAPApiBaseUrl(
  envBaseUrl: string | undefined,
  envGateways: string | undefined,
  businessUnit: string
): string {
  let apiURL = envBaseUrl || ''

  const sapGateways = envGateways || ''
  if (businessUnit && sapGateways) {
    sapGateways.split(',').forEach((item) => {
      const [, unit, url] = item.match(/([^:]*):(.*)/) ?? []
      if (unit === businessUnit && url) {
        apiURL = url
      }
    })
  }

  return apiURL
}

export function getSAPSpecificationApiForBusinessUNIT(businessUnit: string) {
  const apiURL = getSAPApiBaseUrl(
    import.meta.env.VITE_API_SAIFTY_SAP_BASE_URL || window.ENV.API_SAP_BASE_URL,
    window.ENV.API_SAP_GATEWAYS_PER_BUSINESS_UNITS || import.meta.env.VITE_API_SAP_GATEWAYS_PER_BUSINESS_UNITS,
    businessUnit
  )

  const configApi = new Configuration({ basePath: apiURL })
  return new SAPSpecificationApi(configApi, undefined, axiosInstance)
}

export function getSAPconfigLoadManagementForBusinessUNIT(businessUnit: string) {
  const apiURL = getSAPApiBaseUrl(
    import.meta.env.VITE_API_SAP_SYNC_CONFIG_BASE_URL || window.ENV.API_SAP_SYNC_CONFIG_BASE_URL,
    window.ENV.API_SAP_GATEWAYS_PER_BUSINESS_UNITS || import.meta.env.VITE_API_SAP_GATEWAYS_PER_BUSINESS_UNITS,
    businessUnit
  )

  const configApi = new Configuration({ basePath: apiURL })
  return new SAPConfigLoadManagementApi(configApi, undefined, axiosInstance)
}

// SAIFTY GPT
export const gptApi = new DefaultApi(configSaiftyGPTApis, undefined, axiosInstance)
